/**
 * Foursource page
 * https://foursource.com/case-study/yellow-river
 */

import * as React from "react";
import Parser from "html-react-parser";
import { withTrans } from "../../i18n/withTrans";

import { pageTexts } from "../../content/yellow-river";
import { contactTexts } from "../../content/pages/contact";
import { valuesTexts } from "../../content/pages/values";

import confi from "../../images/yellow-river/yellow-river-logo.jpeg";
import giulio from "../../images/yellow-river/eason-wu-yellow-river.jpeg";
import arrowDown from "../../images/confi/arrowDown.svg";

// components
import Layout from "../../components/layout";
import TextImgBackgroundFull from "../../components/textImgBackgroundFull";
import TitleSeparator from "../../components/titleSeparator";
import Text from "../../components/text";
import SmallIconText from "../../components/smallIconText";
import IconText from "../../components/iconText";
import BulletsSection from "../../components/bulletsSection";
import Button from "../../components/_partials/_button";

const SMALLWINDOW = 1920;

const MOBILEWINDOW = 992;

class confiFashionService extends React.Component {
  constructor(props) {
    super(props);
    let width;
    if (typeof window !== `undefined`) {
      width = window.innerWidth;
    }

    this.state = {
      smallWindow: width <= SMALLWINDOW ? true : false,
      mobileWindow: width <= MOBILEWINDOW ? true : false,
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.contactTexts = contactTexts(this.t);
    this.valuesTexts = valuesTexts(this.t);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= SMALLWINDOW ? true : false;
    let mobile = window.innerWidth <= MOBILEWINDOW ? true : false;
    this.setState({
      mobileWindow: mobile,
      smallWindow: show,
    });
  };

  render() {
    return (
      <>
        <Layout pageName="yellow-river" whiteMenu={false}>
          <TextImgBackgroundFull
            info={this.text.topInfo}
          ></TextImgBackgroundFull>
          {this.state.mobileWindow ? (
            <div className="mobile-view-test-case">
              <div
                className={
                  (this.state.smallWindow
                    ? "paddingSidesBig"
                    : "paddingSidesBigger") +
                  " icons d-flex flex-wrap mt-75 justify-content-center"
                }
              >
                <div className="w-75 d-flex flex-column justify-content-between text-center">
                  {this.text.topIcons.map((icon, i) => (
                    <IconText key={i} info={icon}></IconText>
                  ))}
                </div>
              </div>
              <div className="grey-container backgroundGrey topBottomDiagonal pt-100 pb-100 d-flex flex-column paddingSidesBig text-center">
                <div className="confi-logo mb-30">
                  <a
                    className="align-self-center"
                    href="https://pro.foursource.com/yellow-river-srl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="align-self-center"
                      src={confi}
                      alt="confi"
                      placeholder="blurred"
                    />
                  </a>
                </div>
                <div className="about-text mt-70">
                  <TitleSeparator info={this.text.aboutInfo}></TitleSeparator>
                  <div className="info-icons border p-25 backgroundWhite">
                    {this.text.sideIcons1.map((icon, i) => (
                      <SmallIconText key={i} info={icon}></SmallIconText>
                    ))}
                  </div>
                  <a
                    href="#results"
                    className="colorMain d-flex flex-column mt-50 align-items-center"
                  >
                    <p className="pl-10">{this.text.anchorText}</p>
                    <img
                      className="width-25"
                      src={arrowDown}
                      alt="arrowIcon"
                      placeholder="blurred"
                    ></img>
                  </a>
                </div>
              </div>
              <div className="mt-50">
                <div
                  className={
                    (this.state.smallWindow
                      ? "paddingSidesBig"
                      : " paddingSidesBigger") +
                    " flex-container d-flex flex-wrap mb-75 justify-content-between"
                  }
                >
                  <div className="challenges-mobile">
                    <div className="challenges-text text-center mt-75">
                      <TitleSeparator
                        info={this.text.challengesInfo}
                      ></TitleSeparator>
                      <div className="challenges-square border p-25 mt-25 text-center">
                        {this.text.sideIcons2.map((icon, i) => (
                          <SmallIconText key={i} info={icon}></SmallIconText>
                        ))}
                      </div>
                      <div className="favorite-square border p-25 mt-25 text-center">
                        {this.text.sideIcons3.map((icon, i) => (
                          <SmallIconText key={i} info={icon}></SmallIconText>
                        ))}
                        <p>{Parser(this.text.favoriteToolsExtra)}</p>
                      </div>
                      <div className="finding-foursource mt-75">
                        <TitleSeparator
                          info={this.text.findingInfo}
                        ></TitleSeparator>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="results"
                  className={
                    (this.state.smallWindow
                      ? "paddingSidesBig"
                      : "paddingSidesBigger") +
                    " grey-container backgroundGrey topBottomDiagonal pt-100 pb-100 "
                  }
                >
                  <div>
                    <TitleSeparator
                      info={this.text.resultsInfo}
                    ></TitleSeparator>
                    <BulletsSection info={this.text.bullet}></BulletsSection>
                    <Text text={this.text.bottomText}></Text>
                    <div className="mt-20 d-flex flex-column align-items-center">
                      <Button cta={this.text.cta}></Button>
                      <div className="mt-20">
                        <Button cta={this.text.cta2}></Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    (this.state.smallWindow
                      ? "paddingSidesBig"
                      : "paddingSidesBigger") +
                    " profile d-flex flex-wrap pt-50 pb-50 justify-content-around"
                  }
                >
                  <div className="pt-20 pb-20 pl-30 pr-30 border">
                    <div className="font34 font-italic lineHeight2 mb-30 text-center font20">
                      {this.text.profileText}
                    </div>
                    <div className="d-flex flex-wrap flex-column align-items-center">
                      <img
                        className="align-self-center height-150px roundImg"
                        src={giulio}
                        alt="confi"
                        placeholder="blurred"
                      />
                      <div className="d-flex flex-wrap flex-column align-items-center mt-20 ">
                        <span className="colorDark">
                          {this.text.testimonyName}
                        </span>
                        <span className="colorDarkLight">
                          {this.text.testimonyTitle}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="desktop-view-test-case">
              <div
                className={
                  (this.state.smallWindow
                    ? "paddingSidesBig"
                    : "paddingSidesBigger") +
                  " icons d-flex mt-75 justify-content-between"
                }
              >
                <div className="w-75 d-flex justify-content-between mr-75">
                  {this.text.topIcons.map((icon, i) => (
                    <IconText key={i} info={icon}></IconText>
                  ))}
                </div>
                <div className="confi-logo border-left d-flex pl-50 width-260px">
                  <a
                    className="align-self-center"
                    href="https://pro.foursource.com/yellow-river-srl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="align-self-center"
                      src={confi}
                      alt="confi"
                      placeholder="blurred"
                    />
                  </a>
                </div>
              </div>
              <div className="case-study-container-desktop mt-75">
                <div
                  className={
                    (this.state.smallWindow
                      ? "paddingSidesBig"
                      : " paddingSidesBigger") +
                    " flex-container d-flex mb-75 justify-content-between"
                  }
                >
                  <div className="desktop-column-left w-75">
                    <div className="about-text mt-70">
                      <TitleSeparator
                        info={this.text.aboutInfo}
                      ></TitleSeparator>
                      <a
                        href="#results"
                        className="colorMain d-flex align-items-baseline mt-50"
                      >
                        <img
                          className=""
                          src={arrowDown}
                          alt="arrowIcon"
                          placeholder="blurred"
                        ></img>
                        <p className="pl-10">{this.text.anchorText}</p>
                      </a>
                    </div>
                    <div className="challenges-text mt-75">
                      <TitleSeparator
                        info={this.text.challengesInfo}
                      ></TitleSeparator>
                    </div>
                    <div className="finding-foursource mt-75">
                      <TitleSeparator
                        info={this.text.findingInfo}
                      ></TitleSeparator>
                    </div>
                  </div>
                  <div className="desktop-column-right ml-75 width-260px">
                    <div className="info-icons border pl-30 pr-30 pt-20 pb-20 text-center">
                      {this.text.sideIcons1.map((icon, i) => (
                        <SmallIconText key={i} info={icon}></SmallIconText>
                      ))}
                    </div>
                    <div className="challenges-square border pl-30 pr-30 pt-20 pb-20 mt-20 text-center">
                      {this.text.sideIcons2.map((icon, i) => (
                        <SmallIconText key={i} info={icon}></SmallIconText>
                      ))}
                    </div>
                    <div className="favorite-square border pl-30 pr-30 pt-20 pb-20 mt-20 text-center position-relative">
                      {this.text.sideIcons3.map((icon, i) => (
                        <SmallIconText key={i} info={icon}></SmallIconText>
                      ))}
                      <p>{Parser(this.text.favoriteToolsExtra)}</p>
                    </div>
                  </div>
                </div>
                <div
                  id="results"
                  className={
                    (this.state.smallWindow
                      ? "paddingSidesBig"
                      : "paddingSidesBigger") +
                    " grey-container backgroundGrey topBottomDiagonal pt-100 pb-100"
                  }
                >
                  <div className="w-75">
                    <TitleSeparator
                      info={this.text.resultsInfo}
                    ></TitleSeparator>
                    <BulletsSection info={this.text.bullet}></BulletsSection>
                    <Text text={this.text.bottomText}></Text>
                    <div className="mt-20">
                      <Button cta={this.text.cta}></Button>
                      <span className="pl-20">
                        <Button cta={this.text.cta2}></Button>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    (this.state.smallWindow
                      ? "paddingSidesBig"
                      : "paddingSidesBigger") +
                    " profile d-flex pt-100 pb-100 justify-content-around"
                  }
                >
                  <span className="w-75 font34 font-italic lineHeight2">
                    {this.text.profileText}
                  </span>
                  <div className="d-flex flex-column align-items-center pr-25">
                    <img
                      className="align-self-center height-150px roundImg"
                      src={giulio}
                      alt="confi"
                      placeholder="blurred"
                    />
                    <div className="d-flex flex-column align-items-center mt-20 ">
                      <span className="colorDark">
                        {this.text.testimonyName}
                      </span>
                      <span className="colorDarkLight">
                        {this.text.testimonyTitle}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </>
    );
  }
}

export default withTrans(confiFashionService);
