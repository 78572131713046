// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/yellow-river/yellow-river-header-image.jpeg";
import Dollar from "../images/confi/dollar.svg";
import Clock from "../images/yellow-river/Clock.svg";
import Handshake from "../images/confi/handshake.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import Giulio from "../images/yellow-river/eason-wu-yellow-river.jpeg";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("yellow-river.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("yellow-river.subTitle"),
        colorClass: "colorLight",
      },
      /* cta: {
        text: t("yellow-river.topCta"),
        colorClass: "colorLight",
        url: t("yellow-river.ctaUrl"),
        type: "mainColor",
      }, */
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("yellow-river.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("yellow-river.newMarketsValue"),
        },
        subtitle: {
          text: t("yellow-river.newMarketsSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Handshake,
          alt: t("yellow-river.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.roiValue"),
        },
        subtitle: {
          text: t("yellow-river.roiSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Dollar,
          alt: t("yellow-river.roiAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.recruitingValue"),
          textAfter: "% saved time",
          isNum: true,
        },
        subtitle: {
          text: t("yellow-river.recruitingSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Clock,
          alt: t("yellow-river.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("yellow-river.locationTitle"),
        },
        subtitle: {
          text: t("yellow-river.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("yellow-river.locationAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.productTitle"),
        },
        subtitle: {
          text: t("yellow-river.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("yellow-river.productAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.memberTitle"),
        },
        subtitle: {
          text: t("yellow-river.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("yellow-river.memberAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.membershipTitle"),
        },
        subtitle: {
          text: t("yellow-river.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("yellow-river.membershipAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.sizeTitle"),
        },
        subtitle: {
          text: t("yellow-river.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("yellow-river.sizeAlt"),
        },
      },
      {
        title: {
          text: t("yellow-river.sinceTitle"),
        },
        subtitle: {
          text: t("yellow-river.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("yellow-river.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("yellow-river.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("yellow-river.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("yellow-river.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("yellow-river.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("yellow-river.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("yellow-river.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    giulio: [
      {
        title: {
          text: t("yellow-river.giulioName"),
        },
        subtitle: {
          text: t("yellow-river.giulioTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Giulio,
          alt: t("yellow-river.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("yellow-river.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("yellow-river.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("yellow-river.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("yellow-river.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("yellow-river.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("yellow-river.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("yellow-river.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("yellow-river.resultsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("yellow-river.bullet1"),
          t("yellow-river.bullet2"),
          t("yellow-river.bullet3"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText: [
      {
        text: t("yellow-river.bulletBottomText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    cta: {
      text: t("yellow-river.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("yellow-river.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    favoriteToolsExtra: t("yellow-river.favoriteToolsExtra"),
    profileText: t("yellow-river.profileText"),
    testimonyName: t("yellow-river.giulioName"),
    testimonyTitle: t("yellow-river.giulioTitle"),
    anchorText: t("yellow-river.anchorText")
  };
};
